import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../components/layout"
import PageHeader from "../components/page/page-header"
import Blocks from "../components/blocks"

const PageTemplate = ({ data }) => {
  const { content, events } = data

  return (
    <Layout>
      <HelmetDatoCms seo={content.seoMetaTags} />
      <article className="pt-5 pb-20 md:pt-10 md:pb-40">
        <PageHeader {...content} />
        <Blocks blocksData={content.blocks} events={events} />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query PageTemplateQuery($id: String!, $currentDate: Date!) {
    content: datoCmsPage(id: { eq: $id }) {
      ...PageHeader
      ...Blocks
      seoMetaTags {
        tags
      }
    }
    events: allDatoCmsEvent(
      filter: { datetime: { gte: $currentDate } }
      sort: { order: ASC, fields: datetime }
    ) {
      edges {
        node {
          id
          dateSlug: datetime(formatString: "yyyy-MM-DD")
          dateNice: datetime(formatString: "dd, D.M.yyyy", locale: "de")
          artist {
            name
            slug
            genre
            image {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: {
                  auto: "compress"
                  crop: "faces, entropy"
                  fit: "crop"
                  w: "1024"
                  h: "576"
                }
              )
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
