import React from "react"
import { Field } from "formik"

import FormField from "../../form-field"

import { HONORIFIC_PREFIX_OPTIONS } from "./constants"

export function PersonalDetails() {
  return (
    <div>
      <h2 className="text-xl font-black leading-none md:text-2xl">
        Ihre persönliche Daten
      </h2>
      <div className="mt-5">
        <div>
          <FormField label="Anrede" className="w-32" name="salutation">
            <Field
              as="select"
              name="salutation"
              // autoComplete="honorific-prefix"
              autoComplete="off"
            >
              {HONORIFIC_PREFIX_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Field>
          </FormField>
        </div>
        <div className="mt-5">
          <div className="-mx-4 md:flex">
            <div className="px-4 md:w-1/2">
              <FormField label="Vorname" name="firstName">
                <Field
                  type="text"
                  name="firstName"
                  // autoComplete="given-name"
                  autoComplete="off"
                />
              </FormField>
            </div>
            <div className="px-4 mt-4 md:mt-0 md:w-1/2">
              <FormField label="Nachname" name="lastName">
                <Field
                  type="text"
                  name="lastName"
                  // autoComplete="family-name"
                  autoComplete="off"
                />
              </FormField>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="-mx-4 md:flex">
            <div className="px-4 md:w-1/2">
              <FormField label="E-Mail" name="email">
                <Field
                  type="email"
                  name="email"
                  // autoComplete="email"
                  autoComplete="off"
                />
              </FormField>
            </div>
            <div className="px-4 mt-4 md:mt-0 md:w-1/2">
              <FormField label="Telefonnummer" name="phone" optional>
                <Field
                  type="tel"
                  name="phone"
                  // autoComplete="tel"
                  autoComplete="off"
                />
              </FormField>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
