import { useFormikContext } from "formik"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

export function FieldBase({ children, description, isOptional, label, name }) {
  const fieldProps = useFormikContext()

  const showErrorState = !!fieldProps?.errors[name] && fieldProps?.touched[name]

  const fieldContainerClassNames = classNames(
    "flex min-h-[48px] items-center justify-between overflow-hidden rounded border relative",
    "ring-0 focus-within:ring-2 ring-transparent focus-within:ring-opal-600 transition-all duration-200",
    showErrorState
      ? "bg-red-100 border-red-500 ring-2 ring-opal-600"
      : "bg-white border-opal-600"
  )

  return (
    <label className="flex flex-col justify-start gap-1">
      <p>
        {label}{" "}
        {isOptional && (
          <span className="ml-1 text-xs opacity-75">Optional</span>
        )}
      </p>
      <div className={fieldContainerClassNames}>{children}</div>
      {!!description && <p className="text-sm opacity-80">{description}</p>}
      {showErrorState && (
        <p className="flex items-center gap-1 text-xs font-semibold leading-tight">
          <svg className="h-5 w-5 fill-opal-700" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>{fieldProps?.errors[name]}</span>
        </p>
      )}
    </label>
  )
}

FieldBase.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  description: PropTypes.string,
  isOptional: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
}
