import React from "react"
import PropTypes from "prop-types"
import { FieldBase } from "."
import { Field } from "formik"

export function FieldText({
  description,
  isOptional,
  label,
  name,
  type = "text",
  unit,
  ...props
}) {
  return (
    <FieldBase
      name={name}
      label={label}
      description={description}
      isOptional={isOptional}
    >
      <Field
        autoComplete="off"
        className="flex-grow self-stretch bg-transparent px-3 outline-0"
        name={name}
        type={type}
        {...props}
      />
      {!!unit && (
        <div className="grid min-w-[48px] flex-shrink-0 place-items-center self-stretch border-l border-opal-400 bg-opal-300">
          {unit}
        </div>
      )}
    </FieldBase>
  )
}

FieldText.propTypes = {
  description: PropTypes.string,
  isOptional: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["email", "number", "password", "tel", "text"]),
  unit: PropTypes.string,
}
