import React from "react"
import { Link } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ResourceCard = ({ type, title, description, image, url }) => {
  return (
    <article className="flex flex-col h-full">
      <GatsbyImage image={getImage(image)} alt="" />
      <h3 className="mt-8 text-2xl font-black leading-none">{title}</h3>
      <div
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div className="pt-4 mt-auto">
        {type === `page` && (
          <Link to={url} className="link-highlight">
            Mehr erfahren
          </Link>
        )}
        {type === `resource` && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            download
            className="link-highlight"
          >
            Jetzt herunterladen
          </a>
        )}
      </div>
    </article>
  )
}

export default ResourceCard
