import { format, setDay, addWeeks } from "date-fns"
import { de } from "date-fns/locale"

export function getSaturdays(excludedDates = [], soldOutDates = []) {
  const currentDate = new Date()
  const machineFormattedCurrentDate = format(currentDate, "yyyy-MM-dd")

  let dates = []
  let datesIndex = 0

  while (dates.length < 8) {
    const computedDate = addWeeks(currentDate, datesIndex)
    const saturday = setDay(computedDate, 6)

    const machineFormattedDay = format(saturday, "yyyy-MM-dd")
    const friendlyFormattedDay = format(saturday, "d. MMMM yyyy", {
      locale: de,
    })
    const isSoldOut = soldOutDates.includes(machineFormattedDay)

    if (
      !excludedDates.includes(machineFormattedDay) &&
      machineFormattedDay !== machineFormattedCurrentDate
    ) {
      dates.push({
        label: !isSoldOut
          ? `Samstag, ${friendlyFormattedDay}`
          : `Samstag, ${friendlyFormattedDay} (ausverkauft)`,
        value: machineFormattedDay,
        disabled: isSoldOut,
      })
    }

    datesIndex++
  }

  return dates
}
