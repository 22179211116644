import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Container from "../container"
import Map from "../map"

const Contact = ({ title }) => {
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsSetup {
        address
        businessHours
        contactDetails
        location {
          latitude
          longitude
        }
        officeHours
      }
    }
  `)

  return (
    <Container>
      {title && (
        <h2 className="mb-4 text-lg font-black leading-tight md:mb-8 md:text-2xl">
          {title}
        </h2>
      )}
      <div>
        <div className="bg-white md:flex">
          <div className="w-full max-w-sm p-8">
            <div>
              <h3 className="text-xs font-black uppercase leading-none">
                Adresse
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: data.address }}
                className="rich-text mt-2"
              />
            </div>
            <div className="mt-8">
              <h3 className="text-xs font-black uppercase leading-none">
                Kontakt
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: data.contactDetails }}
                className="rich-text mt-2"
              />
            </div>
            <div className="mt-8">
              <h3 className="text-xs font-black uppercase leading-none">
                Markt-Öffnungszeiten
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: data.businessHours }}
                className="rich-text mt-2"
              />
            </div>
            <div className="mt-8">
              <h3 className="text-xs font-black uppercase leading-none">
                Büro-Öffnungszeiten
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: data.officeHours }}
                className="rich-text mt-2"
              />
            </div>
            <div className="mt-16 md:mt-32">
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${data.location.latitude},${data.location.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-black">Anfahrt</span> (via Google Maps)
              </a>
            </div>
          </div>
          <div className="relative flex-grow">
            <div className="h-64 w-full bg-peach md:absolute md:inset-0 md:h-full">
              <Map lng={data.location.longitude} lat={data.location.latitude} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment Contact on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockContact {
        id
        model {
          apiKey
        }
        title
      }
    }
  }
`

export default Contact
