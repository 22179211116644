import { Form, Formik } from "formik"
import { graphql } from "gatsby"
import { post } from "axios"
import { useMutation } from "react-query"
import * as yup from "yup"
import React from "react"
import toast from "react-hot-toast"
import { API_PATH } from "../../constants/api"
import Container from "../container"
import { FieldConsent, FieldSelect, FieldText, FieldTextArea } from "../form"

function Button({ children, isLoading, type }) {
  return (
    <button
      className="inline-flex h-12 items-center justify-center rounded-full bg-peach px-6 font-black leading-none tracking-wide text-opal-800"
      disabled={isLoading}
      type={type}
    >
      {isLoading && (
        <svg
          className="-ml-1 mr-3 h-5 w-5 animate-spin text-opal-800"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {children}
    </button>
  )
}

const ContactForm = ({ subjects }) => {
  const parsedSubjects = JSON.parse(subjects)

  const SALUTATION_OPTIONS = ["Frau", "Herr"]

  const initialValues = {
    salutation: SALUTATION_OPTIONS[0],
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: parsedSubjects[0],
    message: "",
    gdpr: false,
  }

  const validationSchema = yup.object().shape({
    salutation: yup
      .string()
      .oneOf(SALUTATION_OPTIONS)
      .required("Bitte wählen Sie eine Anrede aus"),
    firstName: yup
      .string()
      .max(256, "Eingabe zu lang")
      .required("Bitte geben Sie Ihren Vornamen ein"),
    lastName: yup
      .string()
      .max(256, "Eingabe zu lang")
      .required("Bitte geben Sie Ihren Nachnamen ein"),
    email: yup
      .string()
      .max(256, "Eingabe zu lang")
      .email("Bitte geben Sie eine gültige E-Mail-Adresse ein")
      .required("Bitte geben Sie Ihre E-Mail-Adresse ein"),
    phone: yup.string().max(256, "Eingabe zu lang"),
    subject: yup
      .string()
      .oneOf(parsedSubjects)
      .required("Bitte wählen Sie ein Anliegen aus"),
    message: yup
      .string()
      .max(1024, "Ihre Nachricht ist zu lang")
      .required("Bitte geben Sie eine Nachricht ein"),
    gdpr: yup
      .boolean()
      .oneOf([true], "Bitte stimmen Sie den Datenschutzbestimmungen zu"),
  })

  const mutation = useMutation(
    ({ fieldValues }) => post(`${API_PATH}/submit-contact-form`, fieldValues),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Nachricht erfolgreich versendet")
        variables.actions.resetForm()
      },
      onError: () =>
        toast.error(
          "Etwas ist schiefgegangen. Bitte versuchen Sie es nochmal."
        ),
    }
  )

  const handleOnSubmit = async (fieldValues, actions) => {
    mutation.mutate({ fieldValues, actions })
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <div className="grid gap-8">
            <div className="w-40">
              <FieldSelect
                name="salutation"
                label="Anrede"
                options={SALUTATION_OPTIONS}
              />
            </div>
            <div className="grid gap-8 md:grid-cols-2">
              <FieldText label="Vorname" name="firstName" />
              <FieldText label="Nachname" name="lastName" />
            </div>
            <div className="grid gap-8 md:grid-cols-2">
              <FieldText label="E-Mail" name="email" type="email" />
              <FieldText
                label="Telefonnummer"
                name="phone"
                type="tel"
                isOptional
              />
            </div>
            <FieldSelect
              name="subject"
              label="Anliegen"
              options={parsedSubjects}
            />
            <FieldTextArea label="Nachricht" name="message" />
            <FieldConsent name="gdpr" />
            <div>
              <Button isLoading={mutation.isLoading} type="submit">
                Anfrage absenden
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Container>
  )
}

export const query = graphql`
  fragment ContactForm on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockContactForm {
        id
        model {
          apiKey
        }
        subjects
      }
    }
  }
`

export default ContactForm
