import React from "react"
import Select from "react-select"

import tw from "twin.macro"

export default function SelectField({
  options,
  field,
  form,
  isMulti,
  errorMessage,
}) {
  const handleChange = (option) => {
    let value

    if (!isMulti) {
      value = option.value
    } else {
      value = option.map((item) => item.value)
    }

    form.setFieldValue(field.name, value)
  }

  const styles = {
    control: () => tw`flex items-center h-12`,
    multiValue: () => tw`flex m-0.5 rounded bg-opal-300`,
  }

  const value = !isMulti
    ? options.find((option) => option.value === field.value)
    : field.value.map((fieldValue) =>
        options.find((option) => option.value === fieldValue)
      )

  return (
    <>
      <div>
        <Select
          options={options}
          name={field.name}
          value={value}
          onChange={handleChange}
          onBlur={field.onBlur}
          isMulti={isMulti}
          styles={styles}
          placeholder="Bitte auswählen…"
        />
      </div>
      {errorMessage && (
        <p className="mt-2 text-xs leading-tight">{errorMessage}</p>
      )}
    </>
  )
}
