import React, { useState } from "react"
import { graphql } from "gatsby"
import { animated, useSpring } from "react-spring"
import { useSize } from "react-use"

import Container from "../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const [carouselSized, { width }] = useSize(
    ({ width }) => (
      <div className="absolute-important top-0 left-0 right-0 h-0" />
    ),
    { width: 1 }
  )

  const isFirstSlide = currentIndex === 0
  const isLastSlide = currentIndex === images.length - 1
  const sliderXTranslation = currentIndex * width * -1

  const sliderProps = useSpring({
    transform: `translateX(${sliderXTranslation}px)`,
  })

  return (
    <Container>
      <div className="relative w-full overflow-hidden">
        {carouselSized}
        <div className="overflow-hidden">
          <animated.div style={sliderProps} className="flex">
            {images.map((image, index) => (
              <div key={`image-${index}`} className="w-full flex-shrink-0">
                <GatsbyImage image={getImage(image)} alt={image.alt || ""} />
              </div>
            ))}
          </animated.div>
        </div>
        <button
          onClick={() => setCurrentIndex(currentIndex - 1)}
          disabled={isFirstSlide}
          className={`${
            isFirstSlide ? `hidden` : `flex`
          } absolute top-1/2 left-0 h-24 w-24 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-black pr-2 pl-12 focus:outline-none`}
        >
          <svg viewBox="0 0 32 64" className="h-16 stroke-current text-white">
            <path
              d="m9 45 12-13-12-13"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              transform="matrix(-1 0 0 1 30 0)"
            />
          </svg>
        </button>
        <button
          onClick={() => setCurrentIndex(currentIndex + 1)}
          disabled={isLastSlide}
          className={`${
            isLastSlide ? `hidden` : `flex`
          } absolute top-1/2 right-0 h-24 w-24 translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-black pl-2 pr-12 focus:outline-none`}
        >
          <svg viewBox="0 0 32 64" className="h-16 stroke-current text-white">
            <path
              d="m11 45 12-13-12-13"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment Carousel on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockCarousel {
        id
        model {
          apiKey
        }
        images {
          alt
          gatsbyImageData(
            width: 1024
            height: 640
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: {
              auto: "compress"
              crop: "faces, entropy"
              fit: "crop"
              h: "640"
              w: "1024"
            }
          )
        }
      }
    }
  }
`

export default Carousel
