import React from "react"
import { graphql } from "gatsby"

import Container from "../container"

const CenteredText = ({ title, body }) => {
  return (
    <Container>
      <div className="w-full max-w-3xl mx-auto text-center">
        {title && (
          <h2 className="mb-5 text-3xl font-black leading-tight sm:text-4xl">
            {title}
          </h2>
        )}
        <div dangerouslySetInnerHTML={{ __html: body }} className="rich-text" />
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment CenteredText on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockCenteredText {
        id
        model {
          apiKey
        }
        title
        body
      }
    }
  }
`

export default CenteredText
