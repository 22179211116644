import { Field, useFormikContext } from "formik"
import React from "react"
import PropTypes from "prop-types"

export function FieldConsent({ name }) {
  const fieldProps = useFormikContext()

  const showErrorState = !!fieldProps?.errors[name] && fieldProps?.touched[name]

  return (
    <label className="flex max-w-2xl gap-3">
      <div className="relative grid aspect-square h-6 cursor-pointer place-items-center">
        <Field
          autoComplete="off"
          className="peer absolute"
          type="checkbox"
          name={name}
        />
        <div className="absolute inset-0 aspect-square h-6 rounded bg-opal-400 ring-opal-100 peer-checked:bg-opal-800 peer-focus:ring-2" />
        <svg
          className="absolute h-5 w-5 fill-peach opacity-0 peer-checked:opacity-100"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="grid gap-2 text-sm">
        <p>
          Hiermit erkläre ich mich einverstanden, dass meine Angaben für die
          Beantwortung meiner Anfrage bzw. Kontaktaufnahme verwendet werden.
        </p>
        <p>
          Weitere Informationen finden Sie in unserer{" "}
          <a
            href="/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Datenschutzerklärung
          </a>
          .
        </p>
        {showErrorState && (
          <p className="flex items-center gap-1 text-xs font-semibold leading-tight">
            <svg className="h-5 w-5 fill-opal-700" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>{fieldProps?.errors[name]}</span>
          </p>
        )}
      </div>
    </label>
  )
}

FieldConsent.propTypes = {
  name: PropTypes.string.isRequired,
}
