import React from "react"
import { graphql } from "gatsby"

import Accordion from "./accordion"
import Carousel from "./carousel"
import CenteredText from "./centered-text"
import Contact from "./contact"
import ContactForm from "./contact-form"
import EventsList from "./events-list"
import ImageText from "./image-text"
import InfoTable from "./info-table"
import Pennyfarthing from "./pennyfarthing"
import PressForm from "./press-form"
import Quote from "./quote"
import { ReservationForm } from "./ReservationForm"
import ResourcesList from "./resources-list"
import { Slideshow } from "./slideshow"
import Title from "./title"
import Trifecta from "./trifecta"
import Video from "./video"

const Blocks = ({ blocksData, events }) => {
  return (
    <>
      {blocksData.map((block) => (
        <section key={block.id} className="mt-32 md:mt-40">
          {block.model.apiKey === `block_accordion` && <Accordion {...block} />}
          {block.model.apiKey === `block_carousel` && <Carousel {...block} />}
          {block.model.apiKey === `block_centered_text` && (
            <CenteredText {...block} />
          )}
          {block.model.apiKey === `block_contact` && <Contact {...block} />}
          {block.model.apiKey === `block_contact_form` && (
            <ContactForm {...block} />
          )}
          {block.model.apiKey === `block_events_list` && (
            <EventsList {...block} events={events} />
          )}
          {block.model.apiKey === `block_image_text` && (
            <ImageText {...block} />
          )}
          {block.model.apiKey === `block_info_table` && (
            <InfoTable {...block} />
          )}
          {block.model.apiKey === `block_pennyfarthing` && (
            <Pennyfarthing {...block} />
          )}
          {block.model.apiKey === `block_press_form` && (
            <PressForm {...block} />
          )}
          {block.model.apiKey === `block_quote` && <Quote {...block} />}
          {block.model.apiKey === `block_reservation_form` && (
            <ReservationForm {...block} />
          )}
          {block.model.apiKey === `block_resources_list` && (
            <ResourcesList {...block} />
          )}
          {block.model.apiKey === `block_slideshow` && (
            <Slideshow {...block} events={events} />
          )}
          {block.model.apiKey === `block_title` && <Title {...block} />}
          {block.model.apiKey === `block_trifecta` && <Trifecta {...block} />}
          {block.model.apiKey === `block_video` && <Video {...block} />}
        </section>
      ))}
    </>
  )
}

export const query = graphql`
  fragment Blocks on DatoCmsPage {
    ...Accordion
    ...Carousel
    ...CenteredText
    ...Contact
    ...ContactForm
    ...EventsList
    ...ImageText
    ...InfoTable
    ...Pennyfarthing
    ...PressForm
    ...Quote
    ...ReservationForm
    ...ResourcesList
    ...Slideshow
    ...Title
    ...Trifecta
    ...Video
  }
`

export default Blocks
