import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { useSize, useWindowSize } from "react-use"
import { animated, useSpring } from "react-spring"
import { css } from "@emotion/react"

import Container from "../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import rays from "../../images/rays.svg"

const Trifecta = ({ pages }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const [slidesContainerSize, { width: slidesContainerWidth }] = useSize(
    ({ width }) => (
      <div className="absolute-important top-0 left-0 right-0 h-0" />
    ),
    { width: 1 }
  )

  const { width: windowWidth } = useWindowSize()
  const slidesContainerProps = useSpring({
    transform: `translateX(${slidesContainerWidth * currentSlide * -1}px)`,
  })

  return (
    <Container>
      <div className="relative z-10 -mx-5 md:mx-0">
        <div className="relative overflow-hidden">
          {slidesContainerSize}
          <animated.div style={windowWidth < 768 ? slidesContainerProps : {}}>
            <div className="flex justify-between">
              {pages.map((page, index) => (
                <div
                  key={page.id}
                  className="w-full flex-shrink-0 px-5 md:px-0"
                  css={css`
                    @media (min-width: 768px) {
                      padding-top: ${index === 1
                        ? `12.5%`
                        : index === 2
                        ? `6.25%`
                        : ``};
                      width: ${index === 1 ? `35.9375%` : `25.78125%`};
                    }
                  `}
                >
                  {index === 1 ? (
                    <div className="hidden w-full max-w-none md:block">
                      <GatsbyImage image={getImage(page.imageSquare)} alt="" />
                    </div>
                  ) : (
                    <div className="hidden md:block">
                      <GatsbyImage image={getImage(page.image)} alt="" />
                    </div>
                  )}
                  <div className="w-full max-w-none md:hidden">
                    <GatsbyImage image={getImage(page.imageSmall)} alt="" />
                  </div>
                  <div className="bg-white p-8 md:mt-8 md:bg-transparent md:p-0">
                    <h2 className="text-2xl font-black leading-tight">
                      {page.title}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: page.description }}
                      className="rich-text mt-4"
                    />
                    <div className="mt-4">
                      <Link to={`/${page.slug}`} className="link-highlight">
                        Mehr erfahren
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </animated.div>
        </div>
        <div className="mt-5 px-5 md:hidden">
          <div className="-mx-1 flex justify-end md:hidden">
            <div className="px-1">
              <button
                onClick={() => setCurrentSlide(currentSlide - 1)}
                disabled={currentSlide === 0}
                className={`block ${currentSlide === 0 ? `opacity-25` : ``}`}
              >
                <svg
                  viewBox="0 0 48 48"
                  className="h-10 stroke-current text-black"
                >
                  <circle cx="24" cy="24" r="22" strokeWidth="2" fill="none" />
                  <polyline
                    points="28,12, 15,24, 28,36"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="px-1">
              <button
                onClick={() => setCurrentSlide(currentSlide + 1)}
                disabled={currentSlide === pages.length - 1}
                className={`block ${
                  currentSlide === pages.length - 1 ? `opacity-25` : ``
                }`}
              >
                <svg
                  viewBox="0 0 48 48"
                  className="h-10 stroke-current text-black"
                >
                  <circle cx="24" cy="24" r="22" strokeWidth="2" fill="none" />
                  <polyline
                    points="20,12, 33,24, 20,36"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="pointer-events-none absolute top-1/2 left-1/2 z-[-1] hidden w-3/4 -translate-y-1/2 -translate-x-1/2 md:block">
          <img
            src={rays}
            alt=""
            className="pointer-events-none origin-center animate-spin-rays opacity-50"
            aria-hidden
          />
        </div>
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment Trifecta on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockTrifecta {
        id
        model {
          apiKey
        }
        pages {
          id
          slug
          image {
            gatsbyImageData(
              width: 264
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                auto: "compress"
                crop: "faces, entropy"
                fit: "crop"
                w: "264"
                h: "330"
              }
            )
          }
          imageSquare: image {
            gatsbyImageData(
              width: 736
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                auto: "compress"
                crop: "faces, entropy"
                fit: "crop"
                w: "736"
                h: "736"
              }
            )
          }
          imageSmall: image {
            gatsbyImageData(
              width: 1024
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                auto: "compress"
                crop: "faces, entropy"
                fit: "crop"
                w: "1024"
                h: "576"
              }
            )
          }
          title
          description
        }
      }
    }
  }
`

export default Trifecta
