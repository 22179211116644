import React from "react"
import { graphql } from "gatsby"

import Container from "../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReservationCta from "../reservation-cta"

import anchor from "../../images/anchor.svg"

const PageHeader = ({ title, description, image, hasReservationCta, body }) => {
  return (
    <header>
      <Container>
        <div className="lg:flex lg:items-baseline lg:justify-between">
          <h1 className="text-3xl font-black leading-none md:text-4xl lg:pr-10">
            {title}
          </h1>
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="mt-5 max-w-lg lg:mt-0 lg:max-w-md"
            />
          )}
        </div>
        {image && (
          <figure className="relative mt-10 lg:mt-20">
            {hasReservationCta && (
              <div className="absolute right-0 z-10 -mt-8 -mr-2 md:-mt-16 md:-mr-8 lg:right-auto lg:mr-0 lg:-ml-8">
                <ReservationCta />
              </div>
            )}
            <GatsbyImage image={getImage(image)} alt={image.alt || ""} />
          </figure>
        )}
        {image && body && (
          <div className="md:-mb-20 md:flex">
            <div className="mt-10 md:mt-20 md:w-2/3">
              <div
                dangerouslySetInnerHTML={{ __html: body }}
                className="rich-text max-w-lg md:mx-auto md:pb-20"
              />
            </div>
            <div className="hidden md:block md:w-1/3">
              <img
                src={anchor}
                alt=""
                className="mx-auto origin-top animate-wiggle-slow"
              />
            </div>
          </div>
        )}
      </Container>
    </header>
  )
}

export const query = graphql`
  fragment PageHeader on DatoCmsPage {
    title
    description
    image {
      alt
      gatsbyImageData(
        width: 1024
        height: 448
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: {
          auto: "compress"
          crop: "faces, entropy"
          fit: "crop"
          w: "1024"
          h: "448"
          q: 85
        }
      )
    }
    hasReservationCta
    body
  }
`

export default PageHeader
