import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { useSize, useTimeoutFn } from "react-use"
import { css } from "@emotion/react"
import { animated, useSpring } from "react-spring"

import Container from "../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReservationCta from "../reservation-cta"

export const Slideshow = ({
  delay = 10000,
  events,
  hasReservationCta,
  showNextEvent,
  slides,
}) => {
  const hasEvents = events.edges.length > 0

  const [currentIndex, setCurrentIndex] = useState(0)
  const totalPages =
    showNextEvent && hasEvents ? slides.length + 1 : slides.length

  const [imageContainerSize, { width: imageContainerWidth }] = useSize(
    ({ width }) => (
      <div className="absolute-important top-0 left-0 right-0 h-0" />
    ),
    { width: 1 }
  )

  const [contentContainerSize, { width: contentContainerWidth }] = useSize(
    ({ width }) => (
      <div className="absolute-important top-0 left-0 right-0 h-0" />
    ),
    { width: 1 }
  )

  function fn() {
    if (currentIndex === totalPages - 1) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex + 1)
    }

    reset()
  }

  const reset = useTimeoutFn(fn, delay)[2]

  const imageContainerStyles = useSpring({
    transform: `translateX(${imageContainerWidth * currentIndex * -1}px)`,
  })

  const contentContainerStyles = useSpring({
    transform: `translateX(${contentContainerWidth * currentIndex * -1}px)`,
  })

  return (
    <Container>
      {hasReservationCta && (
        <div className="relative w-full">
          <div className="absolute right-0 z-10 -mt-8 -mr-2 md:-mt-16 md:-mr-8 lg:right-auto lg:mr-0 lg:-ml-8">
            <ReservationCta />
          </div>
        </div>
      )}
      <div className="relative aspect-video overflow-hidden">
        {imageContainerSize}
        <div className="absolute top-0 left-0 h-full w-full">
          {imageContainerWidth ? (
            <animated.div
              style={imageContainerStyles}
              className="flex"
              css={css`
                width: ${imageContainerWidth * totalPages}px;
              `}
            >
              {showNextEvent && hasEvents && (
                <div className="w-full">
                  <GatsbyImage
                    image={getImage(events.edges[0].node.artist.image)}
                    alt=""
                  />
                </div>
              )}
              {slides.map((slide) => (
                <div key={slide.id} className="w-full">
                  <GatsbyImage image={getImage(slide.image)} alt="" />
                </div>
              ))}
            </animated.div>
          ) : (
            <div key={slides[0].id} className="w-full">
              <GatsbyImage image={getImage(slides[0].image)} alt="" />
            </div>
          )}
        </div>
      </div>
      <div className="sm:flex sm:justify-between">
        <div className="relative z-10 -mt-20 flex w-full flex-shrink justify-end overflow-hidden p-5 md:-mt-40 md:p-10">
          <div
            className="relative w-full max-w-lg overflow-hidden bg-white"
            css={css`
              &::before,
              &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                background-image: linear-gradient(
                  to right,
                  hsla(0, 0%, 100%, 1),
                  hsla(0, 0%, 100%, 0)
                );
                width: 20px;
                z-index: 1;

                @media (min-width: 768px) {
                  width: 40px;
                }
              }

              &::after {
                right: 0;
                transform: rotateZ(180deg);
              }
            `}
          >
            {contentContainerSize}
            {contentContainerWidth ? (
              <animated.div
                style={contentContainerStyles}
                className="flex h-full"
                css={css`
                  width: ${contentContainerWidth * totalPages}px;
                `}
              >
                {showNextEvent && hasEvents && (
                  <div className="flex h-full w-full flex-col px-5 py-8 md:p-10">
                    <h2 className="text-2xl font-black leading-none md:text-3xl">
                      Live-Musik
                    </h2>
                    <p className="mt-5">
                      {events.edges[0].node.artist.genre} mit{" "}
                      {events.edges[0].node.artist.name} am{" "}
                      <time dateTime={events.edges[0].node.datetime}>
                        {events.edges[0].node.dateNice}
                      </time>{" "}
                      in unserem Antik- und Trödelzelt am Aachener Platz.
                    </p>
                    <div className="mt-auto pt-5">
                      <Link
                        to={`/musikprogramm/${events.edges[0].node.dateSlug}-${events.edges[0].node.artist.slug}`}
                        className="link-highlight"
                      >
                        Mehr erfahren
                      </Link>
                    </div>
                  </div>
                )}
                {slides.map((slide) => (
                  <div
                    key={slide.id}
                    className="flex h-full w-full flex-col px-5 py-8 md:p-10"
                  >
                    <h2 className="text-2xl font-black leading-none md:text-3xl">
                      {slide.title}
                    </h2>
                    <p className="mt-5">{slide.description}</p>
                    {slide.page && (
                      <div className="mt-auto pt-5">
                        <Link
                          to={`/${slide.page.slug}`}
                          className="link-highlight"
                        >
                          {slide.ctaLabel ? slide.ctaLabel : `Mehr erfahren`}
                        </Link>
                      </div>
                    )}
                  </div>
                ))}
              </animated.div>
            ) : (
              <div
                key={slides[0].id}
                className="flex h-full w-full flex-col px-5 py-8 md:p-10"
              >
                <h2 className="text-2xl font-black leading-none md:text-3xl">
                  {slides[0].title}
                </h2>
                <p className="mt-5">{slides[0].description}</p>
                {slides[0].page && (
                  <div className="mt-auto pt-5">
                    <Link
                      to={`/${slides[0].page.slug}`}
                      className="link-highlight"
                    >
                      {slides[0].ctaLabel
                        ? slides[0].ctaLabel
                        : `Mehr erfahren`}
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {contentContainerWidth !== 0 && (
          <div className="flex-shrink-0 p-5 sm:order-first md:p-10">
            <div className="-m-1 flex justify-end">
              <div className="flex-shrink-0 p-1">
                <button
                  disabled={currentIndex === 0}
                  onClick={() => {
                    reset()
                    setCurrentIndex(currentIndex - 1)
                  }}
                  className={`block ${currentIndex === 0 ? `opacity-25` : ``}`}
                >
                  <svg
                    viewBox="0 0 48 48"
                    className="h-10 stroke-current text-black"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="22"
                      strokeWidth="2"
                      fill="none"
                    />
                    <polyline
                      points="28,12, 15,24, 28,36"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="p-1">
                <button
                  disabled={currentIndex === totalPages - 1}
                  onClick={() => {
                    reset()
                    setCurrentIndex(currentIndex + 1)
                  }}
                  className={`block ${
                    currentIndex === totalPages - 1 ? `opacity-25` : ``
                  }`}
                >
                  <svg
                    viewBox="0 0 48 48"
                    className="h-10 stroke-current text-black"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="22"
                      strokeWidth="2"
                      fill="none"
                    />
                    <polyline
                      points="20,12, 33,24, 20,36"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment Slideshow on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockSlideshow {
        id
        model {
          apiKey
        }
        showNextEvent
        delay
        hasReservationCta
        slides {
          ... on DatoCmsSlide {
            id
            title
            description
            image {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: {
                  auto: "compress"
                  crop: "faces, entropy"
                  fit: "crop"
                  w: "1024"
                  h: "576"
                }
              )
            }
            ctaLabel
            page {
              ... on DatoCmsPage {
                id
                slug
              }
            }
          }
        }
      }
    }
  }
`
