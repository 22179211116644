import React from "react"
import { Field } from "formik"

import { FieldBase } from "."

export function FieldTextArea({
  name,
  description,
  isOptional,
  label,
  ...props
}) {
  return (
    <FieldBase
      name={name}
      label={label}
      description={description}
      isOptional={isOptional}
    >
      <Field
        autoComplete="off"
        as="textarea"
        className="min-h-[46px] flex-grow self-stretch bg-transparent p-3 outline-0"
        name={name}
        rows="10"
        {...props}
      />
    </FieldBase>
  )
}
