import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import pointer from "../images/badge-book-stand-pointer.svg"

const ReservationCta = () => {
  const { setup } = useStaticQuery(graphql`
    query ReservationCtaQuery {
      setup: datoCmsSetup {
        reservationPage {
          slug
        }
      }
    }
  `)

  return (
    <Link
      to={`/${setup.reservationPage.slug}`}
      className="relative block h-24 w-24 -rotate-12 overflow-hidden rounded-full bg-peach md:h-32 md:w-32 "
    >
      <StaticImage src="../images/badge-book-stand.png" alt=" " />

      <div className="absolute bottom-0 left-1/2 mb-4 -translate-x-1/2 md:mb-6">
        <img
          src={pointer}
          alt=""
          className="anim h-4 origin-center animate-wiggle md:h-5"
        />
      </div>
    </Link>
  )
}

export default ReservationCta
