import React, { useState } from "react"
import { graphql } from "gatsby"
import { useSpring, animated } from "react-spring"
import { useSize } from "react-use"

import Container from "../container"

const AccordionItem = ({ title, children }) => {
  const [open, setOpen] = useState(false)
  const [isClicking, setIsClicking] = useState(false)

  const [contentSize, { height: contentHeight }] = useSize(
    ({ height }) => (
      <div className="absolute-important top-0 bottom-0 left-0 w-0" />
    ),
    { width: 100, height: 100 }
  )

  const springConfig = {
    tension: 350,
    friction: 25,
  }

  const buttonProps = useSpring({
    transform: isClicking ? `scale(0.98)` : `scale(1)`,
  })
  const iconProps = useSpring({
    config: springConfig,
    transform: open ? `rotateZ(90deg)` : `rotateZ(0deg)`,
  })
  const rectProps = useSpring({
    config: springConfig,
    opacity: open ? 0 : 1,
    transform: open ? `rotateZ(90deg) scaleX(0.5)` : `rotateZ(0deg) scaleX(1)`,
  })

  const contentContainerProps = useSpring({
    config: springConfig,
    height: open ? contentHeight : 0,
  })

  return (
    <article className="border-t border-opal-500 py-4 md:py-8">
      <div className="md:flex-no-wrap flex md:items-baseline">
        <div className="flex flex-grow items-center">
          <h3 className="text-2xl font-black leading-none sm:text-3xl md:text-4xl">
            {title}
          </h3>
        </div>
        <div className="pl-8">
          <animated.button
            aria-pressed={open}
            className="flex h-12 w-12 items-center justify-center rounded-full bg-opal-900 focus:outline-none md:h-16 md:w-16"
            onClick={() => setOpen(!open)}
            onMouseDown={() => setIsClicking(true)}
            onMouseUp={() => setIsClicking(false)}
            style={buttonProps}
            type="button"
          >
            <animated.svg
              className="h-4 w-4 fill-current text-white md:h-6 md:w-6"
              style={iconProps}
              viewBox="0 0 24 24"
            >
              <animated.rect
                className="origin-center"
                height="2"
                style={rectProps}
                width="24"
                y="11"
              />
              <rect height="24" width="2" x="11" />
            </animated.svg>
          </animated.button>
        </div>
      </div>
      <animated.div className="overflow-hidden" style={contentContainerProps}>
        <div className="relative">
          {contentSize}
          <div className="pt-4 pr-6 md:flex md:pt-8 md:pr-20">
            <div className="md:flex-grow">{children}</div>
          </div>
        </div>
      </animated.div>
    </article>
  )
}

const Accordion = ({ title, items }) => {
  return (
    <Container>
      <h2 className="text-xl font-black leading-snug md:text-2xl">{title}</h2>
      <ul className="mt-10 divide-y divide-opal-600 border-t border-b border-opal-600">
        {items.map((item) => (
          <li key={item.id}>
            <AccordionItem title={item.title}>
              <div
                dangerouslySetInnerHTML={{ __html: item.text }}
                className="rich-text"
              />
            </AccordionItem>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export const query = graphql`
  fragment Accordion on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockAccordion {
        id
        model {
          apiKey
        }
        title
        items {
          id
          title
          text
        }
      }
    }
  }
`

export default Accordion
