import React, { useEffect } from "react"
import { Field, useFormikContext } from "formik"
import { graphql, useStaticQuery } from "gatsby"
import SelectField from "../../select-field"

import FormField from "../../form-field"

import { getSaturdays } from "./helpers/get-saturdays"

import {
  LOCATION,
  LOCATION_OPTIONS,
  MIN_STAND_SIZE,
  PRODUCT_TYPE_OPTIONS_BY_LOCATION,
} from "./constants"

export function ReservationDetails() {
  const {
    datoCmsSetup: {
      excludedDates,
      soldOutDates,
      productTypeExtraTroedel,
      productTypeExtraNeuware,
      productTypeExtraTroedelNeuware,
    },
  } = useStaticQuery(graphql`
    query {
      datoCmsSetup {
        excludedDates
        soldOutDates
        productTypeExtraTroedel
        productTypeExtraNeuware
        productTypeExtraTroedelNeuware
      }
    }
  `)

  const marketDates = getSaturdays(
    JSON.parse(excludedDates),
    JSON.parse(soldOutDates)
  )

  const { values, setFieldValue } = useFormikContext()

  const minStandSize = MIN_STAND_SIZE[values.standLocation]
  const productTypeOptions =
    PRODUCT_TYPE_OPTIONS_BY_LOCATION[values.standLocation]

  useEffect(() => {
    setFieldValue("standLength", minStandSize)
  }, [minStandSize, setFieldValue])

  useEffect(() => {
    setFieldValue("productType", productTypeOptions[0].name)
  }, [productTypeOptions, values.standLocation, setFieldValue])

  useEffect(() => {
    setFieldValue("productTypeExtra", [])
  }, [values.productType, setFieldValue])

  function composeOptions(values) {
    return values.map((value) => ({
      value,
      label: value,
    }))
  }

  const productTypeExtraOptions = {
    Trödel: composeOptions(JSON.parse(productTypeExtraTroedel)),
    Neuware: composeOptions(JSON.parse(productTypeExtraNeuware)),
    "Trödel und Neuware": composeOptions(
      JSON.parse(productTypeExtraTroedelNeuware)
    ),
  }

  return (
    <div className="mt-10 md:mt-20">
      <h2 className="text-xl font-black leading-none md:text-2xl">
        Angaben zum gewünschten Stand
      </h2>
      <div className="mt-5">
        <div className="-m-4 md:flex">
          <div className="p-4 md:w-1/2">
            <div>
              <FormField label="Datum" name="date">
                <Field name="date" as="select" autoComplete="off">
                  <option value="">Bitte auswählen</option>
                  {marketDates.map((date) => (
                    <option
                      key={date.value}
                      value={date.value}
                      disabled={date.disabled}
                    >
                      {date.label}
                    </option>
                  ))}
                </Field>
              </FormField>
            </div>
            <div className="mt-4">
              <div className="flex -m-4">
                <div className="w-1/2 p-4">
                  <FormField label="Innen/Außen" name="standLocation">
                    <Field name="standLocation" as="select" autoComplete="off">
                      {LOCATION_OPTIONS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Field>
                  </FormField>
                </div>
                <div className="w-1/2 p-4">
                  <FormField label="Standgröße" post="m" name="standLength">
                    <Field
                      type="number"
                      name="standLength"
                      min={minStandSize}
                      max={20}
                      autoComplete="off"
                    />
                  </FormField>
                </div>
              </div>
              <div className="mt-4">
                <FormField label="Warentyp" name="productType">
                  <Field name="productType" as="select" autoComplete="off">
                    {productTypeOptions.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                </FormField>
              </div>
              {values.standLocation === LOCATION.INSIDE && (
                <div className="mt-4">
                  <FormField label="Warenkategorie" name="productTypeExtra">
                    <Field
                      name="productTypeExtra"
                      component={SelectField}
                      options={productTypeExtraOptions[values.productType]}
                      isMulti
                      autoComplete="off"
                    />
                  </FormField>
                </div>
              )}
            </div>
          </div>
          <div className="p-4 md:w-1/2">
            <FormField
              label="Nachricht"
              fullHeight
              optional={
                values.standLocation !== LOCATION.INSIDE ||
                !values.productTypeExtra.includes("Sonstiges")
              }
              name="message"
            >
              <Field as="textarea" name="message" autoComplete="off" />
            </FormField>
          </div>
        </div>
      </div>
    </div>
  )
}
