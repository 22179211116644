import React from "react"
import { graphql } from "gatsby"

import Container from "../container"

const Quote = ({ cite, text }) => {
  return (
    <div className="bg-white py-20 text-center md:py-40">
      <Container>
        {cite && (
          <svg
            className="mb-8 inline-block h-4 fill-current text-opal-900 sm:h-6 md:mb-16 md:h-8"
            viewBox="0 0 40 32"
          >
            <rect x="0" y="0" width="18" height="16" />
            <rect x="22" y="0" width="18" height="16" />
            <path d="M18.0000639,16 C18.0002105,21.3333333 16.6669505,25.3333333 14.0002838,28 C11.3336172,30.6666667 6.6668559,32 1.77635684e-15,32 L1.77635684e-15,24 C3.33331203,24 5.66664536,23.3333333 7,22 C8.33335464,20.6666667 9.00002131,18.6666667 9,16 L18.0000639,16 Z" />
            <path d="M40.0000639,16 C40.0002105,21.3333333 38.6669505,25.3333333 36.0002838,28 C33.3336172,30.6666667 28.6668559,32 22,32 L22,24 C25.333312,24 27.6666454,23.3333333 29,22 C30.3333546,20.6666667 31.0000213,18.6666667 31,16 L40.0000639,16 Z" />
          </svg>
        )}
        <blockquote>
          <div
            className={`rich-text text-2xl font-black md:text-3xl lg:text-4xl ${
              !cite ? `xl:text-5xl` : ``
            } leading-snug`}
          >
            {text}
          </div>
          {cite && (
            <cite className="mt-8 block text-sm italic md:mt-12">{cite}</cite>
          )}
        </blockquote>
        {cite && (
          <svg
            className="mt-8 inline-block h-4 rotate-180 fill-current text-opal-900 sm:h-6 md:mt-16 md:h-8"
            viewBox="0 0 40 32"
          >
            <rect x="0" y="0" width="18" height="16" />
            <rect x="22" y="0" width="18" height="16" />
            <path d="M18.0000639,16 C18.0002105,21.3333333 16.6669505,25.3333333 14.0002838,28 C11.3336172,30.6666667 6.6668559,32 1.77635684e-15,32 L1.77635684e-15,24 C3.33331203,24 5.66664536,23.3333333 7,22 C8.33335464,20.6666667 9.00002131,18.6666667 9,16 L18.0000639,16 Z" />
            <path d="M40.0000639,16 C40.0002105,21.3333333 38.6669505,25.3333333 36.0002838,28 C33.3336172,30.6666667 28.6668559,32 22,32 L22,24 C25.333312,24 27.6666454,23.3333333 29,22 C30.3333546,20.6666667 31.0000213,18.6666667 31,16 L40.0000639,16 Z" />
          </svg>
        )}
      </Container>
    </div>
  )
}

export const query = graphql`
  fragment Quote on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockQuote {
        id
        model {
          apiKey
        }
        cite
        text
      }
    }
  }
`

export default Quote
