import React from "react"
import { graphql } from "gatsby"

import Container from "../container"

const TableHeader = ({ title, subTitle, description }) => {
  return (
    <div className="py-5 md:flex md:justify-between md:items-baseline md:py-10">
      <div className="md:w-4/12">
        <h3 className="text-3xl font-black leading-none md:text-4xl">
          {title}
        </h3>
        {subTitle && <p className="mt-3 leading-snug">{subTitle}</p>}
      </div>
      <div className="mt-5 md:mt-0 md:w-7/12">
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="rich-text"
        />
      </div>
    </div>
  )
}

const TableRow = ({ title, description }) => {
  return (
    <div className="py-5 md:flex md:justify-between md:py-10">
      <div className="md:w-4/12">
        <h4 className="font-black">{title}</h4>
      </div>
      <div className="mt-2 md:mt-0 md:w-7/12">
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="rich-text"
        />
      </div>
    </div>
  )
}

const TableHint = ({ description }) => {
  return (
    <div className="flex py-5 md:py-10">
      <svg viewBox="0 0 24 24" className="flex-shrink-0 h-6">
        <path d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M13,10.5 L11,10.5 L11,17 L13,17 L13,10.5 Z M12,6 C11.1715729,6 10.5,6.67157288 10.5,7.5 C10.5,8.32842712 11.1715729,9 12,9 C12.8284271,9 13.5,8.32842712 13.5,7.5 C13.5,6.67157288 12.8284271,6 12,6 Z" />
      </svg>
      <div
        dangerouslySetInnerHTML={{ __html: description }}
        className="pl-4 -mt-px rich-text"
      />
    </div>
  )
}

const InfoTable = ({ title, sections }) => {
  return (
    <Container>
      <h2 className="text-xl font-black leading-snug md:text-2xl">{title}</h2>
      <div className="mt-5 border-t border-b border-opal-600 divide-y divide-opal-600">
        {sections.map((section) => {
          const { apiKey } = section.model

          return (
            <div key={section.id}>
              {apiKey === `info_table_header` && <TableHeader {...section} />}
              {apiKey === `info_table_row` && <TableRow {...section} />}
              {apiKey === `info_table_hint` && <TableHint {...section} />}
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment InfoTable on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockInfoTable {
        id
        model {
          apiKey
        }
        title
        sections {
          ... on DatoCmsInfoTableRow {
            id
            model {
              apiKey
            }
            title
            description
          }
          ... on DatoCmsInfoTableHeader {
            id
            model {
              apiKey
            }
            title
            subTitle
            description
          }
          ... on DatoCmsInfoTableHint {
            id
            model {
              apiKey
            }
            description
          }
        }
      }
    }
  }
`

export default InfoTable
