import React, { useState } from "react"
import { graphql } from "gatsby"

import Container from "../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Video = ({ video, thumbnail }) => {
  const [isHover, setIsHover] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const embedUrl =
    video.provider === `vimeo`
      ? `https://player.vimeo.com/video/${video.providerUid}?autoplay=1&title=0&byline=0&portrait=0`
      : video.provider === `youtube`
      ? `https://www.youtube-nocookie.com/embed/${video.providerUid}?autoplay=1`
      : video.provider === `facebook`
      ? `https://www.facebook.com/plugins/video.php?href=${encodeURI(
          video.url
        )}&show_text=0&width=560`
      : ``

  return (
    <Container>
      <div className="relative aspect-video">
        {!showVideo ? (
          <div className="absolute inset-0 h-full w-full">
            {thumbnail ? (
              <GatsbyImage
                image={getImage(thumbnail)}
                alt={video.title || ""}
              />
            ) : (
              <img
                src={video.thumbnailUrl}
                alt={video.title}
                className="h-full w-full object-cover"
              />
            )}
            <button
              onClick={() => setShowVideo(true)}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className="absolute inset-0 flex h-full w-full items-center justify-center"
              aria-label="Video abspielen"
            >
              <svg viewBox="0 0 80 80" className="h-24 opacity-75 sm:h-32">
                <circle
                  cx="40"
                  cy="40"
                  r="40"
                  className={`fill-current ${
                    !isHover ? `text-black` : `text-white`
                  } transition-color duration-100`}
                />
                <polygon
                  points="29,20 60,40 29,60"
                  fill="none"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  className={`stroke-current ${
                    !isHover ? `text-white` : `text-black`
                  } transition-color duration-100`}
                />
              </svg>
            </button>
          </div>
        ) : (
          <div className="absolute inset-0 h-full w-full">
            <iframe
              src={embedUrl}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title={video.title}
              className="h-full w-full"
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment Video on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockVideo {
        id
        model {
          apiKey
        }
        video {
          url
          title
          provider
          providerUid
          thumbnailUrl
          width
          height
        }
        thumbnail {
          gatsbyImageData(
            width: 1024
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: {
              auto: "compress"
              crop: "faces, entropy"
              fit: "crop"
              w: "1024"
              h: "576"
            }
          )
        }
      }
    }
  }
`

export default Video
