import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css"

import mapMarker from "../images/map-marker.png"

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

const Map = ({ lng, lat, zoom = 15 }) => {
  const mapContainer = useRef(null)

  const map = useRef(null)
  const [longitude, setLongitude] = useState(lng)
  const [latitude, setLatitude] = useState(lat)
  const [zoomLevel, setZoomLevel] = useState(zoom)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [longitude, latitude],
      zoom: zoomLevel,
      scrollZoom: false,
      doubleClickZoom: false,
    })

    const markerElement = document.createElement("img")
    markerElement.src = mapMarker
    markerElement.className = "marker"

    new mapboxgl.Marker(markerElement).setLngLat([lng, lat]).addTo(map.current)
  })

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on("move", () => {
      setLongitude(map.current.getCenter().lng.toFixed(4))
      setLatitude(map.current.getCenter().lat.toFixed(4))
      setZoomLevel(map.current.getZoom().toFixed(2))
    })
  })

  return (
    <>
      <div ref={mapContainer} className="map-container h-full w-full" />
    </>
  )
}

export default Map
