import React from "react"
import { graphql } from "gatsby"
import { Formik, Form } from "formik"
import * as yup from "yup"
import toast from "react-hot-toast"
import { isAfter } from "date-fns"

import Container from "../../container"

import { PersonalDetails } from "./PersonalDetails"
import { ReservationDetails } from "./ReservationDetails"
import { ReservationFooter } from "./ReservationFooter"

import {
  HONORIFIC_PREFIX,
  HONORIFIC_PREFIX_OPTIONS,
  LOCATION,
  MIN_STAND_SIZE,
  PRODUCT_TYPE_OPTIONS_BY_LOCATION,
} from "./constants"
import { API_PATH } from "../../../constants/api"

export function ReservationForm() {
  const initialValues = {
    salutation: HONORIFIC_PREFIX.MS,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: "",
    standLocation: LOCATION.OUTSIDE,
    standLength: MIN_STAND_SIZE[LOCATION.OUTSIDE],
    productType: PRODUCT_TYPE_OPTIONS_BY_LOCATION[LOCATION.OUTSIDE][0].name,
    productTypeExtra: [],
    message: "",
    priceStand: "",
    priceBasic: "",
    priceTotal: "",
    gdpr: false,
  }

  const validationSchema = yup.object().shape({
    salutation: yup
      .string()
      .label("Anrede")
      .oneOf(HONORIFIC_PREFIX_OPTIONS)
      .required("Bitte wählen Sie eine Anrede aus"),
    firstName: yup
      .string()
      .max(256, "Eingabe zu lang")
      .required("Bitte geben Sie Ihren Vornamen ein"),
    lastName: yup
      .string()
      .max(256, "Eingabe zu lang")
      .required("Bitte geben Sie Ihren Nachnamen ein"),
    email: yup
      .string()
      .max(256, "Eingabe zu lang")
      .email("Bitte geben Sie eine gültige E-Mail-Adresse ein")
      .required("Bitte geben Sie Ihre E-Mail-Adresse ein"),
    phone: yup.string().max(256, "Eingabe zu lang"),
    date: yup
      .date("Bitte geben Sie ein gültiges Datum ein")
      .test(
        "is-future-date",
        "Das Datum muss in der Zukunft liegen",
        (value) => {
          const dateToday = new Date()

          return isAfter(value, dateToday)
        }
      )
      .required("Bitte wählen Sie ein Datum aus"),
    standLocation: yup
      .string()
      .max(256, "Eingabe zu lang")
      .required("Bitte wählen Sie einen Standort aus"),
    standLength: yup
      .number("Die Standgröße muss eine Zahl sein")
      .max(20, "Die maximale Standlänge beträgt 20 Meter")
      .positive("Die Standgröße muss im positiven Bereich liegen")
      .integer("Die Standgröße muss in ganzen Metern angegeben werden")
      .required("Bitte geben Sie die gewünschte Standgröße ein"),
    productType: yup.string().required("Bitte wählen Sie einen Warentyp aus"),
    productTypeExtra: yup.array().when("standLocation", {
      is: (location) => location === LOCATION.INSIDE,
      then: yup
        .array()
        .min(1, "Bitte wählen Sie mindestens eine Produktkategorie aus"),
      otherwise: yup.array(),
    }),
    message: yup
      .string()
      .max(1024, "Ihre Nachricht ist zu lang")
      .when(["standLocation", "productTypeExtra"], {
        is: (location, type) =>
          location === LOCATION.INSIDE && type.includes("Sonstiges"),
        then: yup
          .string()
          .required("Bitte beschreiben Sie die sonstigen Waren"),
        otherwise: yup.string(),
      }),
    priceStand: yup.mixed(),
    priceBasic: yup.mixed(),
    priceTotal: yup.mixed(),
    gdpr: yup
      .boolean()
      .oneOf([true], "Bitte stimmen Sie den Datenschutzbestimmungen zu"),
  })

  const handleSubmit = async (values, { resetForm }) => {
    toast.promise(
      fetch(`${API_PATH}/submit-reservation-form`, {
        method: "POST",
        body: JSON.stringify(values),
      }),
      {
        loading: "Bitte warten…",
        success: () => {
          resetForm()

          return "Anfrage erfolgreich versendet"
        },
        error: "Etwas ist schiefgegangen. Bitte versuchen Sie es nochmal.",
      }
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Container>
          <PersonalDetails />
          <ReservationDetails />
        </Container>
        <ReservationFooter />
      </Form>
    </Formik>
  )
}

export const query = graphql`
  fragment ReservationForm on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockReservationForm {
        id
        model {
          apiKey
        }
      }
    }
  }
`
