import React from "react"
import { graphql } from "gatsby"

import Container from "../container"

const Title = ({ title }) => {
  return (
    <Container>
      <p className="text-4xl leading-none text-center md:text-5xl lg:text-6xl">
        <strong className="font-black">{title}</strong>
      </p>
    </Container>
  )
}

export const query = graphql`
  fragment Title on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockTitle {
        id
        model {
          apiKey
        }
        title
      }
    }
  }
`

export default Title
