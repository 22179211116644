import React from "react"
import tw, { styled } from "twin.macro"
import classNames from "classnames"
import selectArrow from "../images/select-arrow.svg"
import { useFormikContext } from "formik"

const Field = styled.div`
  ${tw`flex-grow h-full`}

  input[type=date],
  input[type=email],
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=text],
  select,
  textarea {
    ${tw`block w-full px-3 bg-transparent rounded`}
  }

  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="text"],
  select {
    ${tw`h-12`}
  }

  textarea {
    ${tw`h-full py-3 resize-none`}

    min-height: 192px;
  }

  select {
    ${tw`pr-12 bg-right bg-no-repeat bg-contain appearance-none`}

    background-image: url(${selectArrow});
  }
`

const FormField = ({
  children,
  className,
  fullHeight,
  label,
  name,
  optional,
  post,
  pre,
}) => {
  const props = useFormikContext()

  const showErrorState = !!props?.errors[name] && props?.touched[name]

  const fieldContainerClassNames = classNames(
    "flex flex-grow mt-1 rounded border",
    showErrorState
      ? "bg-red-100 border-red-500 ring-2 ring-opal-600"
      : "bg-white border-opal-600"
  )

  return (
    <label
      className={`flex flex-col ${fullHeight ? `h-full` : `h-auto`} ${
        className ? className : ``
      }`}
    >
      <p>
        {label}{" "}
        {optional && <span className="ml-1 text-xs opacity-75">Optional</span>}
      </p>
      <div className={fieldContainerClassNames}>
        {pre && (
          <div className="flex min-w-[48px] flex-shrink-0 items-center justify-center rounded-l border-r border-opal-400 bg-opal-300 px-3 text-center">
            {pre}
          </div>
        )}
        <Field>{children}</Field>
        {post && (
          <div className="ml-auto flex min-w-[48px] flex-shrink-0 items-center justify-center rounded-r border-l border-opal-400 bg-opal-300 px-3 text-center">
            {post}
          </div>
        )}
      </div>
      {showErrorState && (
        <p className="mt-2 flex items-center gap-1 text-xs font-semibold leading-tight">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 fill-opal-700"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>{props?.errors[name]}</span>
        </p>
      )}
    </label>
  )
}

export default FormField
