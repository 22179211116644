export const HONORIFIC_PREFIX = {
  MS: "Frau",
  MR: "Herr",
}

export const HONORIFIC_PREFIX_OPTIONS = Object.values(HONORIFIC_PREFIX)

export const LOCATION = {
  OUTSIDE: "Außen",
  INSIDE: "Innen",
}

export const LOCATION_OPTIONS = Object.values(LOCATION)

export const MIN_STAND_SIZE = {
  [LOCATION.OUTSIDE]: 3,
  [LOCATION.INSIDE]: 1,
}

export const PRODUCT_TYPE_OPTIONS_BY_LOCATION = {
  [LOCATION.OUTSIDE]: [
    {
      name: `Trödel`,
      price: 9,
      priceNew: 10,
    },
    {
      name: `Neuware`,
      price: 15,
      priceNew: 16,
    },
    {
      name: `Trödel und Neuware`,
    },
    {
      name: `Lebensmittel/Blumen`,
      price: 18,
      priceNew: 19,
    },
    {
      name: `Propaganda`,
    },
    {
      name: `Imbiss`,
    },
  ],
  [LOCATION.INSIDE]: [
    {
      name: `Trödel`,
      price: 13,
      priceNew: 14,
    },
    {
      name: `Neuware`,
      price: 18,
      priceNew: 19,
    },
    {
      name: `Trödel und Neuware`,
    },
  ],
}
