import React, { useEffect } from "react"
import { Field, useFormikContext } from "formik"

import Container from "../../container"
import Spinner from "../../spinner"

import { PricingRow } from "./PricingRow"

import { LOCATION, PRODUCT_TYPE_OPTIONS_BY_LOCATION } from "./constants"
import { isBefore } from "date-fns"

export function ReservationFooter() {
  const { errors, touched, values, setFieldValue, isSubmitting } =
    useFormikContext()

  const currentProductsForLocation =
    PRODUCT_TYPE_OPTIONS_BY_LOCATION[values.standLocation]
  const currentProduct = currentProductsForLocation.find(
    (option) => option?.name === values.productType
  )

  const { date } = values

  const currentProductPricePerUnit =
    date && isBefore(new Date(date), new Date("2022-05-07"))
      ? currentProduct.price
      : currentProduct.priceNew || currentProduct.price

  const hasQuote = !!currentProductPricePerUnit

  const showErrorMessage =
    Object.keys(errors).length > 0 && Object.keys(touched).length > 0

  // Calculate stand price
  useEffect(() => {
    if (hasQuote) {
      setFieldValue(
        "priceStand",
        currentProductPricePerUnit * values.standLength
      )
    } else {
      setFieldValue("priceStand", "")
    }
  }, [
    hasQuote,
    currentProductPricePerUnit,
    values.productType,
    values.standLength,
    values.standLocation,
    setFieldValue,
  ])

  // Calculate basic price
  useEffect(() => {
    if (hasQuote) {
      if (values.standLocation === LOCATION.OUTSIDE) {
        if (values.productType === "Trödel" && values.standLength < 3) {
          setFieldValue("priceBasic", 0)
        } else {
          setFieldValue("priceBasic", 8)
        }
      } else {
        setFieldValue("priceBasic", 0)
      }
    } else {
      setFieldValue("priceBasic", "")
    }
  }, [
    hasQuote,
    values.productType,
    values.standLength,
    values.standLocation,
    setFieldValue,
  ])

  // Calculate total price
  useEffect(() => {
    setFieldValue("priceTotal", values.priceBasic + values.priceStand)
  }, [values.priceBasic, values.priceStand, setFieldValue])

  return (
    <>
      <Field type="hidden" name="priceBasic" />
      <Field type="hidden" name="priceStand" />
      <Field type="hidden" name="priceTotal" />
      <div className="mt-10 bg-opal-600 py-10 md:mt-20 md:py-20">
        <Container>
          <div className="-m-5 md:flex">
            <div className="p-5 md:w-1/2">
              <h2 className="text-xl font-black leading-none md:text-2xl">
                Kosten für Ihren Stand
              </h2>
            </div>
            <div className="p-5 md:w-1/2">
              {hasQuote ? (
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  className="w-full text-xl leading-none md:text-2xl"
                >
                  <tbody>
                    {!!values.priceBasic && (
                      <PricingRow
                        label="Grundgebühr"
                        price={values.priceBasic}
                      />
                    )}
                    <PricingRow
                      label="Standpreis"
                      price={values.priceStand}
                      hint={
                        values.standLocation === LOCATION.INSIDE &&
                        `Im Winter zzgl. 10€ Heizkosten`
                      }
                    />
                  </tbody>
                  <tfoot>
                    <PricingRow
                      label="Gesamtpreis"
                      price={values.priceTotal}
                      isFooter
                    />
                    {values.standLocation === LOCATION.OUTSIDE && (
                      <tr>
                        <td className="text-sm">
                          <strong>zzgl. 10€ Müllkaution</strong> am Markttag in
                          Bar fällig. Wird am Marktende bei Mitnahme des Abfalls
                          zurückerstattet.
                        </td>
                        <td />
                      </tr>
                    )}
                  </tfoot>
                </table>
              ) : (
                <p>
                  Nach Prüfung Ihrer Angaben senden wir Ihnen ein individuelles
                  Angebot zu.
                </p>
              )}
              <div className="mt-8">
                <label className="flex max-w-2xl gap-3 text-sm">
                  <div className="relative grid aspect-square h-6 cursor-pointer place-items-center">
                    <Field
                      className="peer absolute"
                      type="checkbox"
                      name="gdpr"
                    />
                    <div className="absolute inset-0 aspect-square h-6 rounded bg-opal-400 ring-opal-100 peer-checked:bg-opal-800 peer-focus:ring-2" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute h-5 w-5 fill-peach opacity-0 peer-checked:opacity-100"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>{" "}
                  <div className="grid gap-2">
                    <p>
                      Hiermit erkläre ich mich einverstanden, dass meine Angaben
                      für die Beantwortung meiner Anfrage bzw. Kontaktaufnahme
                      verwendet werden.
                    </p>
                    <p>
                      Weitere Informationen finden Sie in unserer{" "}
                      <a
                        href="/datenschutz"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        Datenschutzerklärung
                      </a>
                      .
                    </p>
                  </div>
                </label>
                {touched.gdpr && errors.gdpr && (
                  <p className="mt-2 flex items-center gap-1 text-xs font-semibold leading-tight">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 fill-opal-700"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{errors.gdpr}</span>
                  </p>
                )}
              </div>
              <div className="mt-10">
                <button
                  type="submit"
                  className="button"
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Spinner className="mr-3 h-5" />}
                  Anfrage absenden
                </button>
              </div>
              {showErrorMessage && (
                <div className="mt-10 flex items-center gap-1 rounded border border-opal-700 bg-opal-500 p-2 font-semibold shadow-lg shadow-opal-700/25">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 fill-opal-700"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Bitte überprüfen Sie Ihre Eingaben.</span>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
