import React from "react"
import { graphql } from "gatsby"

import Container from "../container"
import ResourceCard from "../resource-card"

const ResourcesList = ({ resources }) => {
  return (
    <Container>
      <div className="grid grid-cols-1 gap-24 md:grid-cols-2 md:gap-16">
        {resources.map((resource) => (
          <div
            key={resource.id}
            className="mx-auto w-full max-w-md md:mx-0 md:max-w-none"
          >
            <ResourceCard
              type={resource.model.apiKey}
              image={resource.image}
              title={resource.title}
              description={resource.description}
              url={
                (resource.slug && `/${resource.slug}`) || resource.download.url
              }
            />
          </div>
        ))}
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment ResourcesList on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockResourcesList {
        id
        model {
          apiKey
        }
        resources {
          ... on DatoCmsResource {
            id
            model {
              apiKey
            }
            title
            description
            image {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: {
                  auto: "compress"
                  crop: "faces, entropy"
                  fit: "clamp"
                  w: "600"
                  h: "272"
                }
              )
            }
            download {
              url
            }
          }
          ... on DatoCmsPage {
            id
            model {
              apiKey
            }
            title
            description
            slug
            image {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: {
                  auto: "compress"
                  crop: "faces, entropy"
                  fit: "clamp"
                  w: "600"
                  h: "272"
                }
              )
            }
          }
        }
      }
    }
  }
`

export default ResourcesList
