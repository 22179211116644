import React from "react"
import { graphql } from "gatsby"

import Container from "../container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import rays from "../../images/rays.svg"
import classNames from "classnames"

const ImageText = ({
  title,
  text,
  image,
  hasLargeHeadline,
  hasSmallImage,
  flipped,
  bgColor,
}) => {
  return (
    <div
      className={classNames(bgColor ? `py-20 md:py-40` : "")}
      style={{
        backgroundColor: bgColor && bgColor.hex,
      }}
    >
      <Container>
        <div className="text-center md:-m-5 md:flex md:items-center md:justify-between md:text-left">
          <div
            className={classNames(
              "relative z-10 mx-auto w-56 md:mx-0 md:w-full md:p-5",
              { "md:order-last": !!flipped },
              hasSmallImage ? "max-w-xs" : "max-w-md"
            )}
          >
            <div className="relative z-10">
              <GatsbyImage image={getImage(image)} alt="" />
            </div>
            {!bgColor && (
              <div className="pointer-events-none absolute top-1/2 left-1/2 z-[-1] hidden w-[110%] -translate-y-1/2 -translate-x-1/2 md:block">
                <img
                  src={rays}
                  alt=""
                  className="pointer-events-none origin-center animate-spin-rays opacity-50"
                  aria-hidden
                />
              </div>
            )}
          </div>
          <div className="relative z-10 mx-auto mt-8 w-full max-w-md md:mt-0 md:p-5">
            <h2
              className={`text-3xl font-black sm:text-4xl ${
                hasLargeHeadline ? `md:text-5xl lg:text-6xl` : ``
              } leading-tight`}
            >
              {title}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className="rich-text mt-4"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export const query = graphql`
  fragment ImageText on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockImageText {
        id
        model {
          apiKey
        }
        hasLargeHeadline
        hasSmallImage
        text
        flipped
        image {
          gatsbyImageData(
            width: 640
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { auto: "compress", crop: "faces, entropy", w: "640" }
          )
        }
        title
        bgColor {
          hex
        }
      }
    }
  }
`

export default ImageText
