import React from "react"
import PropTypes from "prop-types"

import { Field } from "formik"

import { FieldBase } from "./FieldBase"

export function FieldSelect({
  description,
  isOptional,
  label,
  name,
  options,
  ...props
}) {
  return (
    <FieldBase
      name={name}
      label={label}
      description={description}
      isOptional={isOptional}
    >
      <Field
        as="select"
        autoComplete="off"
        className="flex-grow appearance-none self-stretch bg-transparent pl-3 pr-14 outline-none"
        name={name}
        {...props}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Field>
      <div className="pointer-events-none absolute right-0 top-0 grid aspect-square h-full place-items-center">
        <svg
          className="h-6 w-6 stroke-opal-700"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </FieldBase>
  )
}

FieldSelect.propTypes = {
  description: PropTypes.string,
  isOptional: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
}
