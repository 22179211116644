import React from "react"
import PropTypes from "prop-types"

export function PricingRow({ label, hint, price, isFooter }) {
  return (
    <tr>
      <th className={`py-3 text-left align-top ${isFooter ? `pt-4` : ``}`}>
        <div className={isFooter ? `pt-5 border-t border-opal-700` : ``}>
          <p className="leading-none">
            <strong className="font-black">{label}</strong>
          </p>
          {hint && (
            <p className="w-full max-w-xs mt-1 text-sm font-normal leading-tight opacity-75">
              {hint}
            </p>
          )}
        </div>
      </th>
      <td
        className={`py-3 w-24 text-right align-top ${isFooter ? `pt-4` : ``}`}
      >
        <div className={isFooter ? `pl-4 pt-5 border-t border-opal-700` : ``}>
          {price} €
        </div>
      </td>
    </tr>
  )
}

PricingRow.propTypes = {
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  price: PropTypes.number,
  isFooter: PropTypes.bool,
}
